
import { defineComponent, reactive, toRefs } from 'vue'
import { checkPermission } from '@/utils/permission' // Use permission directly
import { useI18n } from 'vue-i18n'
import SwitchRoles from './components/SwitchRoles.vue'

export default defineComponent({
  components: {
    SwitchRoles
  },
  setup() {
    const { t } = useI18n()
    const state = reactive({
      key: 1,
      checkPermission: checkPermission,
      handleRolesChange: () => {
        state.key++
      }
    })

    return {
      t,
      ...toRefs(state)
    }
  }
})
